import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/scan.vue')
  },
  {
    path: '/item-num',
    name: 'item-num',
    component: () => import(/* webpackChunkName: "item-num" */ '../views/item-num.vue')
  },
  {
    path: '/order-info',
    name: 'order-info',
    component: () => import(/* webpackChunkName: "order-info" */ '../views/order-info.vue')
  },
  {
    path: '/submitted',
    name: 'submitted',
    component: () => import(/* webpackChunkName: "submitted" */ '../views/submitted.vue')
  },
  {
    path: '*',
    redirect: '/scan'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
